import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { loadPermissao, postAuthMeAction } from '../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import AccountStatement from '../pages/AccountStatement/AccountStatement';
import AccountSubscriptions from '../pages/AccountSubscriptions/AccountSubscriptions';

import CreditCardBillingList from '../pages/CreditCardBillingList/CreditCardBillingList';
import CustomSideBar from '../components/CustomSideBar/CustomSideBar';
import Dashboard from '../pages/Dashboard/Dashboard';
import DetalhesPreConta from '../pages/DetalhesPreConta/DetalhesPreConta';
import EditFees from '../pages/EditFees/EditFees';
import EditarDadosDaConta from '../pages/EditarDadosDaConta/EditarDadosDaConta';

import GerenciarListaDeContas from '../pages/GerenciarListaDeContas/GerenciarListaDeContas';

import ListPartner from '../pages/ListPartners/ListPartners';

import ListaDeContas from '../pages/ListaDeContas/ListaDeContas';

import Logs from '../pages/Logs/Logs';
import ManageFees from '../pages/ManageFees/ManageFees';
import NewAccountFees from '../pages/NewAccountFees/NewAccountFees';
import Payers from '../pages/Payers/Payers';
import PaymentBooklet from '../pages/PaymentBooklet/PaymentBooklet';
import PaymentLink from '../pages/PaymentLink/PaymentLink';
import PaymentSlipList from '../pages/PaymentSlipList/PaymentSlipList';

import RechargeCellphoneDetails from '../pages/RechargeCellphoneDetails/RechargeCellphoneDetails';
import RechargeCellphoneList from '../pages/RechargeCellphoneList/RechargeCellphoneList';
import RechargeCellphoneListAdmin from '../pages/RechargeCellphoneListAdmin/RechargeCellphoneListAdmin';

import TransactionDetails from '../pages/TransactionDetails/TransactionDetails';
import TransactionHistory from '../pages/TransactionHistory/TransactionHistory';
import TransferDetails from '../pages/TransferDetails/TransferDetails';
import TransferHistory from '../pages/TransferHistory/TransferHistory';

import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';
import { useParams } from 'react-router';
import Blacklist from '../pages/Blacklist/Blacklist';

import PaginaBoletos from '../pages/PaginaBoletos/PaginaBoletos';

import Cartoes from '../pages/Cartoes/Cartoes';

import { APP_CONFIG } from '../constants/config';
import Adquirencia from '../pages/Adquirencia/Adquirencia';
import ListaMaquinaVirtualCartao from '../pages/ListaMaquinaVituralCartao/ListaMaquinaVirtualCartao';
import ListaCobrar from '../pages/ListaCobrar/ListaCobrar';
import { getLancamentosFuturos } from '../services/services';
import ListaLancamentosFuturos from '../pages/ListaLancamentosFuturos/ListaLancamentosFuturos';
import ListaPagadores from '../pages/ListaPagadores/ListaPagadores';
import ListaHistoricoDeTransacoes from '../pages/ListaHistoricoDeTransacoes/ListaHistoricoDeTransacoes';
import ListaLinkPagamento from '../pages/ListaLinkPagamento/ListaLinkPagamento';
import NovoLinkPagamento from '../pages/NovoLinkPagamento/NovoLinkPagamento';
import DetalhesLinkPagamento from '../pages/DetalhesLinkPagamento/DetalhesLinkPagamento';
import CobrarUsuario from '../pages/CobrarUsuario/CobrarUsuario';
import SubscriptionPlans from '../pages/SubscriptionPlans/SubscriptionPlans';
import NewSubscriptionPlans from '../pages/NewSubscriptionPlans/NewSubscriptionPlans';
import NewAccountSubscriptions from '../pages/NewAccountSubscriptions/NewAccountSubscriptions';
import ListaTarifas from '../pages/ListaTarifas/ListaTarifas';
import ListaExportacoesSolicitadas from '../pages/ListaExportacoesSolicitadas/ListaExportacoesSolicitadas';
import ListaTerminaisPOS from '../pages/ListaTerminaisPOS/ListaTerminaisPOS';
import DetalhesTerminalPOS from '../pages/DetalhesTerminalPOS/DetalhesTerminalPOS';
import DashboardCobrancaFilter from '../pages/DashboardCobrancaFilter/DashboardCobrancaFilter';
import DashboardFinancasFilter from '../pages/DashboardFinancasFilter/DashboardFinancasFilter';
import DashboardOutrosServicosFilter from '../pages/DashboardTransferenciasFilter/DashboardTransferenciasFilter';
import DashboardTransferenciasFilter from '../pages/DashboardOutrosServicosFilter/DashboardOutrosServicosFilter';
import ListaBoleto from '../pages/ListaBoleto/ListaBoleto';
import GerarBoleto from '../components/GerarBoleto/GerarBoleto';
import ListaGerarNovoBoleto from '../pages/ListaGerarNovoBoleto/ListaGerarNovoBoleto';
import ListaCarne from '../pages/ListaCarne/ListaCarne';
import DashboardRepresentante from '../pages/DashboardRepresentante/DashboardRepresentante';
import ListaExtratoAdquirencia from '../pages/ListaExtratoAdquirencia/ListaExtratoAdquirencia';
import ListaHistoricoDeTransacoesRepresentante from '../pages/ListaHistoricoDeTransacoesRepresentante/ListaHistoricoDeTransacoesRepresentante';
import ListaPlanosDeVenda from '../pages/ListaPlanosDeVenda/ListaPlanosDeVenda';
import PlanoDeVendaDetalhes from '../pages/PlanoDeVendaDetalhes/PlanoDeVendaDetalhes';
import PaymentLinkDetails from '../pages/PaymentLinkAdquirenciaPF/PaymentLinkDetails/PaymentLinkDetails';
import PaymentLinkPay from '../pages/PaymentLinkAdquirenciaPF/PaymentLinkPay/PaymentLinkPay';
import ArquivoRemessaIndex from '../pages/ArquivoRemessa/ArquivoRemessaIndex/ArquivoRemessaIndex';
import ItensRemessa from '../pages/ArquivoRemessa/ItensRemessa/ItensRemessa';
import PagadorRemessaIndex from '../pages/ArquivoRemessa/Pagador/PagadorRemessaIndex';
import ArquivoRemessaForm from '../pages/ArquivoRemessa/ArquivoRemessaForm/ArquivoRemessaForm';
import ArquivoRetornoIndex from '../pages/ArquivoRetorno/ArquivoRetornoIndex';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100vh',
		width: '100%',
		margin: '0px',
		padding: '0px',
	},
	sideBarContainer: {
		display: 'flex',
		FlexDirection: 'column',
		width: '25%',
		height: '100vh',
		margin: '0px',
	},
	contentAreaContainer: {
		backgroundColor: '#fff',
		width: '100%',
		/* padding: '50px 50px 10px 50px', */
	},
	pageHeaderContainer: {
		backgroundColor: 'white',
		width: '100%',
		height: '35%',
	},
}));

const SwitchContents = () => {
	const classes = useStyles();
	const { section, id, subsection, subsectionId } = useParams();
	const token = useAuth();
	const dispatch = useDispatch();
	const me = useSelector((state) => state.me);
	const userPermissao = useSelector((state) => state.userPermissao);
	const userData = useSelector((state) => state.userData);
	const gerenciarPermissao = useSelector((state) => state.gerenciarPermissao);
	const [permissoes, setPermissoes] = useState([]);

	if (token) {
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = APP_CONFIG.crispId;
		(function () {
			var d = document;
			var s = d.createElement('script');
			s.src = 'https://client.crisp.chat/l.js';
			s.async = 1;
			d.getElementsByTagName('head')[0].appendChild(s);
		})();
	}
	useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]);

	let content = null;

	switch (section) {
		case 'home':
			content = <Dashboard />;
			break;

		case 'cobrancas':
			content = <DashboardCobrancaFilter />;
			break;

		case 'financas':
			content = <DashboardFinancasFilter />;
			break;

		case 'transferencias':
			content = <DashboardTransferenciasFilter />;
			break;

		case 'outros-servicos':
			content = <DashboardOutrosServicosFilter />;
			break;

		case 'extrato':
			content = <AccountStatement />;

			break;

		case 'arquivo-remessa':
			content = <ArquivoRemessaIndex />;

			switch (subsection) {
				case 'itens':
					content = <ItensRemessa />;

					break;
				case 'pagadores':
					content = <PagadorRemessaIndex />;

					break;
				/* case 'editar':
						content = <EditarPagador />;
	
						break; */

				default:
			}
			break;

		case 'criar-arquivo-remessa':
			content = <ArquivoRemessaForm />;

			break;

		case 'detalhes-arquivo-remessa':
			switch (id) {
				default:
					content = <ArquivoRemessaForm />;

					break;
			}
			break;

		case 'arquivo-retorno':
			content = <ArquivoRetornoIndex />;
			break;

		case 'adquirencia':
			switch (subsection) {
				case 'maquina-virtual-cartao':
					content = <ListaMaquinaVirtualCartao />;
					if (subsectionId) {
						content = <TransactionDetails />;
					}

					break;

				case 'cobrar':
					content = <ListaCobrar />;
					if (subsectionId) {
						content = <CobrarUsuario />;
					}
					break;

				case 'lancamentos-futuros':
					content = <ListaLancamentosFuturos />;

					break;

				case 'pagadores':
					content = <ListaPagadores />;

					break;

				case 'historico-de-transacoes':
					content = <ListaHistoricoDeTransacoes />;

					break;

				case 'boleto':
					content = <ListaBoleto />;

					break;
				case 'carne':
					content = <ListaCarne />;

					break;
				case 'gerar-boleto':
					content = <GerarBoleto />;

					break;
				case 'lista-gerar-boleto':
					content = <ListaGerarNovoBoleto />;

					break;

				case 'link-de-pagamento':
					content = <ListaLinkPagamento />;
					if (subsectionId) {
						content = <DetalhesLinkPagamento />;
					}
					break;

				case 'novo-link-pagamento':
					content = <NovoLinkPagamento />;

					break;

				case 'cobranca-recorrente':
					content = <AccountSubscriptions />;

					break;

				case 'nova-assinatura':
					content = <NewAccountSubscriptions />;

					break;

				case 'planos-de-assinaturas':
					content = <SubscriptionPlans />;
					if (subsectionId) {
						content = <NewSubscriptionPlans />;
					}
					break;

				case 'criar-plano-de-assinatura':
					content = <NewSubscriptionPlans />;

					break;

				case 'tarifas':
					content = <ListaTarifas />;

					break;

				case 'exportacoes-solicitadas':
					content = <ListaExportacoesSolicitadas />;

					break;

				case 'terminais-pos':
					content = <ListaTerminaisPOS />;
					if (subsectionId) {
						content = <DetalhesTerminalPOS />;
					}
					break;

				case 'lista-boletos':
					content = <PaginaBoletos />;
					break;
				/*
				isso era dentro do switch adquirencia
				 case 'lista-folhas-de-pagamento':
					content = <ListaFolhaDePagamento />;
					break;

				case 'cadastrar-funcionarios-e-grupos':
					content = <CadastrarFuncionariosGrupos />;
					break;

				case 'cadastrar-folha-de-pagamento':
					content = <CadastrarFolhaDePagamento />;
					break;

				case 'lista-funcionarios-e-grupos':
					content = <ListaFuncionariosGrupos />;
					break;

				case 'consultar-pagamentos':
					content = <ListaConsultaPagamento />;
					break;

				case 'arquivos-lote':
					content = <ListaArquivosLote />;
					break;

				case 'print':
					content = <PrintFolhaDePagamento />;
					break; */
				default:
			}

			break;

		/* case 'representante':
			switch (subsection) {
				case 'adm':
					content = <DashboardRepresentante />;
					break;

				default:
			}
			break; */

		case 'adm':
			content = userData && userData.agent && <DashboardRepresentante />;
			break;

		case 'gerenciar-contas':
			content = userData && userData.agent && <ListaDeContas />;
			break;

		case 'detalhes-transacao':
			content = userData && userData.agent && <TransactionDetails />;
			break;

		case 'historico-de-transacoes':
			content = userData && userData.agent && (
				<ListaHistoricoDeTransacoesRepresentante />
			);
			break;

		case 'planos-de-venda':
			content = userData && userData.agent && <ListaPlanosDeVenda />;

			break;

		case 'plano-de-venda':
			switch (id) {
				default:
					content = userData && userData.agent && <PlanoDeVendaDetalhes />;
					break;
			}
			break;

		case 'logs':
			content = userData && userData.agent && <Logs />;

			break;

		/* case 'taxa':
				switch (subsection) {
					case 'editar': {
						switch (id) {
							default:
								content = <EditFees />;PlanosDeVenda
								break;
						}
						break;
					}
					default:
						content = null;
				}
				break; */

		case 'editar-conta':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<EditarDadosDaConta />
						) : null;
			}

			break;

		case 'gerenciar-lista-contas':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<GerenciarListaDeContas />
						) : null;
					switch (subsection) {
						case 'cobrancas-credito':
							content = <CreditCardBillingList />;
							break;

						case 'boleto':
							content = <PaymentSlipList />;
							break;

						case 'carne':
							content = <PaymentBooklet />;
							break;

						case 'link-pagamento':
							content = <PaymentLinkPay />;
							break;

						case 'detalhes-link':
							content = <PaymentLinkDetails />;

							break;

						/* case 'lancamentos-futuros':
							content = <FutureTransactions />;
							break; */

						/* case 'extrato':
							content =
								permissoes.includes(
									'Atendimento - Consulta de extrato'
								) ||
								permissoes.includes('Administrador - Acesso total') ? (
									<AccountStatement />
								) : null;
							break; */

						case 'assinaturas':
							content = <AccountSubscriptions />;
							break;

						case 'historico-de-transacoes':
							content = <TransactionHistory />;

							break;

						case 'detalhes-transacao':
							content = <TransactionDetails />;

							break;

						case 'historico-transferencia':
							content = <TransferHistory />;

							break;

						case 'pagadores':
							content = <Payers />;
							break;

						case 'recarga-celular':
							content = <RechargeCellphoneList />;
							break;

						case 'detalhes-recarga':
							content = <RechargeCellphoneDetails />;
							break;

						case 'detalhes-transferencia':
							content = <TransferDetails />;
							break;

						case 'extrato-adquirencia':
							content = <ListaExtratoAdquirencia />;
							break;

						case 'terminais-pos':
							content = <ListaTerminaisPOS />;
							break;

						case 'exportacoes-solicitadas':
							content = <ListaExportacoesSolicitadas />;
							break;

						case 'tarifas':
							content = <ListaTarifas />;
							break;

						default:
					}
			}
			break;

		case 'taxas':
			content = <ManageFees />;
			break;

		case 'partners':
			content = <ListPartner />;
			break;

		case 'recarga-celular-admin':
			content =
				permissoes.includes('Parceiros - Visualizar Recargar') ||
				permissoes.includes('Administrador - Acesso total') ? (
					<RechargeCellphoneListAdmin />
				) : null;
			break;

		case 'taxa':
			switch (subsection) {
				case 'editar': {
					switch (id) {
						default:
							content = <EditFees />;
							break;
					}
					break;
				}
				default:
					content = null;
			}
			break;

		case 'nova-taxa':
			content = <NewAccountFees />;
			break;

		/* case 'lista-pre-contas':
			content = <GerenciarListaPreContas />;
			break; */

		case 'cartoes':
			content = APP_CONFIG.AbaCartoes && <Cartoes />;
			break;

		case 'lista-boletos':
			content = <PaginaBoletos />;
			break;

		case 'blacklist':
			content = <Blacklist />;
			break;

		case 'detalhes-pre-conta':
			switch (id) {
				default:
					content = <DetalhesPreConta />;
					break;
			}
			break;

		default:
			content = null;
			break;
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.sidebarContainer}>
				{subsection && subsection === 'print' ? null : (
					<CustomSideBar cadastro={false} />
				)}

				<Box className={classes.pageHeaderContainer}></Box>
			</Box>

			<Box className={classes.contentAreaContainer}>{content}</Box>
		</Box>
	);
};

export default SwitchContents;
