import { Box, Icon, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CardRegistration from './CardRegistration/CardRegistration';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import {
	loadLinkPagamentoIdPagar,
	postLinkPagamentoPay,
	postPagadoresPagar,
} from '../../../../actions/actions';
import { APP_CONFIG } from '../../../../constants/config';
import useAuth from '../../../../hooks/useAuth';
import NovoPagadorLink from '../../../../components/NovoPagadorLink/NovoPagadorLink';

const useStyles = makeStyles((theme) => ({
	layout: {
		backgroundColor: APP_CONFIG.mainCollors.backgrounds,
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			/* height: '100%', */
		},
	},
	paper: {
		backgroundColor: APP_CONFIG.mainCollors.backgrounds,
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '0 0 0 27px',
		[theme.breakpoints.down('sm')]: {
			borderRadius: '0px',
			margin: 0,
		},
	},
	stepper: {
		backgroundColor: APP_CONFIG.mainCollors.backgrounds,
		padding: theme.spacing(3, 0, 5),
		height: '60px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		color: 'white',
		marginTop: theme.spacing(3),
	},
	headerPaper: {
		backgroundColor: APP_CONFIG.mainCollors.backgrounds,
		padding: theme.spacing(2),
		borderRadius: '27px 0 0 0 ',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			borderRadius: '0px',
		},
	},
}));

const STEPS = {
	DADOS_DO_PAGADOR: 'Dados do pagador', //payer registration
	DADOS_DO_CARTAO: 'Dados do cartão', // card registration
	CONCLUIDO: 'Conclusão', // card registration
};

const PaymentLinkPaySteps = ({ id, pagadorId }) => {
	const [steps] = useState([
		STEPS.DADOS_DO_PAGADOR,
		STEPS.DADOS_DO_CARTAO,
		STEPS.CONCLUIDO,
	]);
	const token = useAuth();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		dispatch(loadLinkPagamentoIdPagar(id));
	}, []);

	const linkPagamentoId = useSelector((state) => state.linkPagamentoIdPagar);
	const resPostPagador = useSelector((state) => state.pagadorPagar);

	const [errosPagador, setErrosPagador] = useState({});
	const [errosLink, setErrosLink] = useState({});

	const [activeStep, setActiveStep] = useState(0);
	const [pagador, setPagador] = useState({
		conta_id: '',
		documento: '',
		nome: '',
		celular: '',
		email: '',
		data_nascimento: '',
		endereco: {
			cep: ' ',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});
	const [linkPagamentoPagar, setLinkPagamentoPagar] = useState({
		pagador_id: '',
		parcelas: '',
		cartao: {
			nome: '',
			numero: '',
			cvv: '',
			mes: '',
			ano: '',
			focus: '',
		},
	});

	const history = useHistory();

	useEffect(() => {
		if (pagadorId) {
			toast.success('Link com pagador já vinculado', {
				autoClose: 6000,
				position: 'top-center',
			});
			setActiveStep(1);
		}
	}, [pagadorId]);

	const STEPS_COMPONENTS = {
		[STEPS.DADOS_DO_PAGADOR]: (
			<NovoPagadorLink
				errosPagador={errosPagador}
				pagador={pagador}
				setPagador={setPagador}
				linkPagamentoPagar={linkPagamentoPagar}
			/>
		),
		[STEPS.DADOS_DO_CARTAO]: (
			<CardRegistration
				errosLink={errosLink}
				linkPagamentoPagar={linkPagamentoPagar}
				setLinkPagamentoPagar={setLinkPagamentoPagar}
				pagador={pagador}
				limiteParcelas={linkPagamentoId.limite_parcelas}
			/>
		),
		[STEPS.CONCLUIDO]: (
			<Box display="flex" flexDirection="column" alignItems="center">
				<Typography variant="h4" align="center">
					Pagamento Efetuado com Sucesso!
				</Typography>
				<Box style={{ marginTop: '40px' }}>
					<CustomButton
						color="purple"
						onClick={() => history.push('/login')}
					>
						<Typography>Finalizar</Typography>
					</CustomButton>
				</Box>
			</Box>
		),
	};

	const handleClick = async () => {
		setLoading(true);
		// cadastro de pagador
		if (activeStep === 0) {
			let newPagador = pagador;
			if (linkPagamentoId && linkPagamentoId.conta_id) {
				newPagador = {
					...newPagador,
					conta_id: linkPagamentoId.conta_id,
				};

				setPagador(newPagador);
			}

			/* setPagador({ ...pagador, conta_id: linkPagamentoId.conta_id }); */

			const resPagador = await dispatch(postPagadoresPagar(newPagador));

			if (resPagador) {
				setErrosPagador(resPagador);
				setActiveStep(0);
				setLoading(false);
			} else {
				toast.success('Cadastro efetuado com sucesso!');
				setActiveStep(activeStep + 1);
				setLoading(false);
			}
		}

		// cadastro do cartao e pagamento do link

		if (activeStep === 1) {
			let newPagar = linkPagamentoPagar;
			if (resPostPagador && resPostPagador.id) {
				newPagar = {
					...newPagar,
					pagador_id: resPostPagador.id,
				};
				setLinkPagamentoPagar(newPagar);
			} else if (linkPagamentoId && linkPagamentoId.pagador_id) {
				newPagar = {
					...newPagar,
					pagador_id: linkPagamentoId.pagador_id,
				};
				setLinkPagamentoPagar(newPagar);
			}

			const resLink = await dispatch(postLinkPagamentoPay(newPagar, id));

			if (resLink) {
				if (resLink.success === false) {
					toast.error('Erro ao efetuar a transação');
					toast.error('Código do erro: ' + resLink.message_display);
				}
				setErrosLink(resLink);
				setActiveStep(1);
				setLoading(false);
			} else {
				toast.success('Link pago com sucesso!');
				setLoading(false);
				setActiveStep(activeStep + 1);
			}
		}
	};

	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<Box className={classes.layout}>
				<Paper className={classes.headerPaper}>
					<img
						/* style={
							matches
								? { height: '4rem', width: '6rem' }
								: { height: '100%', width: '9rem' }
						} */

						src={APP_CONFIG.assets.smallColoredLogo}
						alt="logo"
					/>

					<Box alignSelf="center">
						<Typography variant="h6">Dúvidas ?</Typography>
						<Typography variant="body">
							{APP_CONFIG.emailContato}
						</Typography>
					</Box>
				</Paper>
				<Paper className={classes.paper}>
					<Stepper
						activeStep={activeStep}
						className={classes.stepper}
						alternativeLabel
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<Box
						display="flex"
						flexDirection="column"
						margin="0"
						padding="0"
					>
						{STEPS_COMPONENTS[steps[activeStep]]}
						{activeStep < 2 ? (
							<Box className={classes.buttons}>
								<Button
									style={{
										borderRadius: '27px',
										backgroundColor: APP_CONFIG.mainCollors.primary,
									}}
									variant="contained"
									onClick={handleClick}
									className={classes.button}
									type="submit"
								>
									{activeStep === steps.length - 1
										? 'Pagar link - R$ ' + linkPagamentoId.valor
										: 'Próximo'}
								</Button>
							</Box>
						) : null}
					</Box>
				</Paper>
			</Box>
		</Box>
	);
};

export default PaymentLinkPaySteps;
