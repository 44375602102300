/* import {
	Box,
	Button,
	Grid,
	makeStyles,
	Modal,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
	getAcessoWebAction,
	postAcessarWebAction,
	postLoginAction,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { get } from 'lodash';

import { useLocation } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import ClipLoader from 'react-spinners/ClipLoader';

import CustomButton from '../../components/CustomButton/CustomButton';
import { getAcessoWeb } from '../../services/services';
import { toast } from 'react-toastify';
import { APP_CONFIG } from '../../constants/config';
import QRCode from 'react-qr-code';

const useStyles = makeStyles((theme) => ({
	homeRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		margin: '0px',
		padding: '0px',
		background: APP_CONFIG.mainCollors.primaryGradient,
	},
	pagesRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		margin: '0px',
		padding: '0px',
		background: APP_CONFIG.mainCollors.primaryGradient,
	},
	homeContentContainer: {
		[theme.breakpoints.down('md')]: {
			padding: '40px 100px',
		},
		padding: '40px 300px',
	},
	smallHomeContentContainer: {},
	pagesContentContainer: {
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			padding: '40px 100px',
		},
		[theme.breakpoints.down(850)]: {
			padding: '40px 40px',
		},
		padding: '40px 250px',
	},
	modal: {
		outline: ' none',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'center',
		position: 'absolute',
		borderRadius: '27px',
		top: '40%',
		left: '43.1%',

		width: '260px',
		height: '200px',
		backgroundColor: '#F6F6FA',

		border: '0px solid #000',
		boxShadow: 24,
	},
}));

const Login = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const small = useMediaQuery(theme.breakpoints.down(1090));
	const classes = useStyles();
	const history = useHistory();
	const [openModal, setOpenModal] = useState(false);
	const qrCodeValue = useSelector((state) => state.qrCodeValue);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const counter = useRef(0);
	const imageLoaded = () => {
		setLoading(false);
	};
	const [loginSteps, setLoginSteps] = useState(false);

	const { section, id, subsection } = useParams();
	let location = useLocation();
	let content = null;

	const handleLogin = async (id) => {
		const resAcessarWeb = await getAcessoWeb(id);
		if (resAcessarWeb.data.token_jwt) {
			await localStorage.setItem(
				'@auth',
				JSON.stringify({
					access_token: resAcessarWeb.data.token_jwt,
					token_type: 'bearer',
					expires_in: 3600,

					login_time: new Date().getTime(),
				})
			);
			history.push('/dashboard/home');
			return;
		}

		setTimeout(() => {
			handleLogin(id);
		}, 5000);
	};

	const handleTimeout = () => {
		setTimeout(() => {
			setOpenModal(true);
		}, 120000);
	};

	useEffect(() => {
		dispatch(postAcessarWebAction());

		handleTimeout();
	}, []);

	useEffect(() => {
		if (qrCodeValue && qrCodeValue.id) {
			handleLogin(qrCodeValue.id);
		}
	}, [qrCodeValue.id]);

	

	return (
		<Box className={classes.pagesRoot}>
			<Modal open={openModal}>
				<Box className={classes.modal}>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							marginTop: '30px',
						}}
					>
						<Typography
							style={{
								fontSize: '19px',
								color: APP_CONFIG.mainCollors.primary,

								marginLeft: '10px',
							}}
						>
							QrCode expirado.
						</Typography>
						<Typography
							style={{
								fontSize: '19px',
								color: APP_CONFIG.mainCollors.primary,

								marginLeft: '10px',
							}}
						>
							Volte para gerar um novo!
						</Typography>
						<Box style={{ marginTop: '30px' }}>
							<CustomButton
								color="purple"
								onClick={() => {
									setOpenModal(false);
									window.location.reload();
								}}
							>
								<Typography
									style={{
										fontSize: '10px',
										color: 'white',
									}}
								>
									VOLTAR
								</Typography>
							</CustomButton>
						</Box>
					</Box>
				</Box>
			</Modal>

			<Box
				style={{
					zIndex: 30,
					display: 'flex',
					alignSelf: 'center',
					marginTop: '40px',
					flexDirection: 'column',

					width: '50%',
					maxWidth: '500px',
					padding: '15px',
					borderRadius: 20,
				}}
			>
				<img
					className={classes.logo}
					alt="Logo"
					src={APP_CONFIG.assets.smallWhiteLogo}
					style={{
						marginTop: '10px',
						marginBottom: 20,
						width: '50%',
						maxWidth: '200px',
						alignSelf: 'center',
					}}
				/>
				
				{small ? (
					<Box
						style={{
							paddingTop: '10px',
							display: 'flex',
							flexDirection: 'column',
							alignSelf: 'center',
							display: 'flex',
						}}
					>
						<a
							href={`itabank://itabank/qr-code/${id}`}
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<Box style={{ display: 'flex', alignSelf: 'center' }}>
								<CustomButton
									style={{
										color: APP_CONFIG.mainCollors.primary,
									}}
								>
									ABRIR APLICATIVO
								</CustomButton>
							</Box>

							<Box
								style={{
									display: 'flex',
									alignSelf: 'center',
									flexDirection: 'column',
									marginTop: '20px',
									backgroundColor: 'white',
									padding: '10px',
									borderRadius: 20,

									boxShadow: '0.5px 0.5px 0.5px 0.5px #9E9E9E',
								}}
							>
								<Box
									style={{
										alignSelf: 'center',
									}}
								>
									{qrCodeValue && qrCodeValue.otp ? (
										<QRCode value={qrCodeValue.otp} />
									) : (
										<ClipLoader
											style={{ alignSelf: 'center' }}
											size={100}
											loading={true}
										/>
									)}
								</Box>
							</Box>
						</a>
					</Box>
				) : (
					<>
						<Typography
							variant="subtitle1"
							style={{
								fontSize: !small ? 20 : '0.9em',
								marginTop: '20px',
								textAlign: 'center',
								marginLeft: 35,
								marginRight: 35,
								color: '#F0F0F0',
							}}
						>
							Abra o aplicativo do {APP_CONFIG.smallName} no celular e
							escaneie o Qr Code abaixo:{' '}
						</Typography>
						<Box
							style={{
								display: 'flex',
								alignSelf: 'center',
								flexDirection: 'column',
								marginTop: '20px',
								backgroundColor: 'white',
								padding: '10px',
								borderRadius: 20,

								boxShadow: '0.5px 0.5px 0.5px 0.5px #9E9E9E',
							}}
						>
							<Box
								style={{
									alignSelf: 'center',
								}}
							>
								{qrCodeValue && qrCodeValue.otp ? (
									<QRCode value={qrCodeValue.otp} />
								) : (
									<ClipLoader
										style={{ alignSelf: 'center' }}
										loading={true}
										size={100}
									/>
								)}
							</Box>
						</Box>
					</>
				)}

				<Typography
					variant="subtitle1"
					style={{
						fontSize: !small ? 20 : '0.9em',
						marginTop: '20px',
						textAlign: 'center',
						marginLeft: 35,
						marginRight: 35,
						color: '#F0F0F0',
					}}
				>
					Não tem conta no {APP_CONFIG.smallName}?
				</Typography>

				<a
					href={APP_CONFIG.linkApp}
					style={{
						fontSize: !small ? 20 : '0.9em',
						color: '#ED757D',
						alignSelf: 'center',
					}}
				>
					{' '}
					Baixe o app gratuitamente
				</a>
			</Box>
		</Box>
	);
};

export default Login; */

import {
	BottomNavigation,
	Box,
	Grid,
	Typography,
	useTheme,
} from '@material-ui/core';
import Modal from '@mui/material/Modal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import CustomButton from '../../components/CustomButton/CustomButton';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import InputMask from 'react-input-mask';
import {
	getAcessoWebAction,
	postAcessarWebAction,
	postLoginAction,
} from '../../actions/actions';

import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import LoginSVG from '../../assets/vBankPJAssets/LoginSVG.svg';
import vBankSmallLogo from '../../assets/vBankPJAssets/vBankSmallLogo.svg';
import qrcodeSample from '../../assets/vBankPJAssets/qrcodeSample.svg';
import QRCode from 'react-qr-code';
import { getAcessoWeb } from '../../services/services';
import { APP_CONFIG } from '../../constants/config';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DownloadIcon from '@mui/icons-material/Download';
import LockIcon from '@mui/icons-material/Lock';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',

		[theme.breakpoints.down('1024')]: {
			flexDirection: 'column',
		},
	},

	leftBoxStyle1: {
		display: 'flex',
		background: APP_CONFIG.mainCollors.primaryGradient,
		width: '50%',
		minHeight: '100vh',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center',

		[theme.breakpoints.down('1024')]: {
			width: '100%',
			minHeight: '0px',
			height: '100%',
		},
	},
	leftBoxStyle2: {
		display: 'flex',

		background: APP_CONFIG.mainCollors.primaryGradient,
		width: '50%',
		minHeight: '100vh',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center',

		[theme.breakpoints.down('1024')]: {
			width: '100%',
			minHeight: '0px',
			height: '100%',
		},
	},
	rightBox: {
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		width: '50%',

		[theme.breakpoints.down('1024')]: {
			width: '100%',
		},
	},

	smallLogoContainer: {
		display: 'flex',
		alignSelf: 'flex-end',
		width: '100px',
		height: '100px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '3px',
		alignItems: 'center',
		marginTop: '80px',
		[theme.breakpoints.down('1024')]: {
			marginTop: '80px',
		},
	},
	QrCodeContainer: {
		marginBottom: '35px',
		display: 'flex',
		flexDirection: 'column',

		alignItems: 'center',

		marginTop: '100px',
	},

	stepsContainer: {
		marginTop: '60px',
		flexDirection: 'column',
		display: 'flex',
	},

	stepContainer: {
		marginTop: '10px',
		flexDirection: 'row',
		display: 'flex',
		alignSelf: 'flex-start',
	},
	appBarContainer: {
		width: '100%',
		height: '80px',
		backgroundColor: 'white',
		zIndex: 2,
	},
	contentContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
	},
	backgroundImage1: {
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		width: '10%',
	},
	formContainer: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '80%',
		zIndex: 2,
	},
	backgroundImage2: {
		height: '100%',
		width: '10%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
	},
	cadastrarButton: {
		color: 'black',
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(1.05)',
		},
	},
	modal: {
		outline: ' none',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'center',
		position: 'absolute',

		top: '40%',
		left: '43.1%',

		width: '260px',
		height: '200px',
		backgroundColor: '#F6F6FA',

		border: '0px solid #000',
		boxShadow: 24,
	},
}));
export default function SignIn() {
	const [loginSteps, setLoginSteps] = useState(false);
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const [errorLogin, setErrorLogin] = useState(false);
	const qrCodeValue = useSelector((state) => state.qrCodeValue);
	const [openModal, setOpenModal] = useState(false);
	const [values, setValues] = useState({
		documento: '',
		senha: '',
	});

	const handleLogin = async (id) => {
		const resAcessarWeb = await getAcessoWeb(id);
		if (resAcessarWeb.data.token_jwt) {
			await localStorage.setItem(
				'@auth',
				JSON.stringify({
					access_token: resAcessarWeb.data.token_jwt,
					token_type: 'bearer',
					expires_in: 3600,

					login_time: new Date().getTime(),
				})
			);
			history.push('/dashboard/home');
			return;
		}

		setTimeout(() => {
			handleLogin(id);
		}, 5000);
	};

	const handleTimeout = () => {
		setTimeout(() => {
			setOpenModal(true);
		}, 120000);
	};

	const handleAcessarWeb = async () => {
		const id = await dispatch(postAcessarWebAction());
		if (id) {
			setLoginSteps(true);
			handleLogin(id);
			handleTimeout();
		} else {
			toast.error('Falha ao gerar QrCode');
		}
	};

	return (
		<Box className={classes.root}>
			{APP_CONFIG.titleLogin === 'Bankzz' ? (
				<>
					<Box className={classes.leftBoxStyle2}>
						<img
							src={APP_CONFIG.assets.backgroundLogo}
							style={{ position: 'fixed' }}
						/>
						<Box
							style={{
								alignSelf: 'self-end',
								marginBottom: '60px',
								marginLeft: '200px',
							}}
						>
							<img src={APP_CONFIG.assets.loginSvg} />
						</Box>
					</Box>
				</>
			) : (
				<Box className={classes.leftBoxStyle1}>
					<img src={APP_CONFIG.assets.loginSvg} style={{ width: '70%' }} />
				</Box>
			)}
			<Box className={classes.rightBox}>
				<Box
					className={classes.smallLogoContainer}
					style={{
						width:
							APP_CONFIG.titleLogin === 'Acium BNK Empresas' ||
							APP_CONFIG.titleLogin === 'xBank'
								? '200px'
								: null,
					}}
				>
					<img
						alt=""
						src={APP_CONFIG.assets.smallColoredLogo}
						style={{
							marginRight:
								APP_CONFIG.titleLogin === 'Simer Bank' ? '80px' : '0px',
						}}
					/>
				</Box>

				{loginSteps ? (
					<Box className={classes.QrCodeContainer}>
						<Typography
							style={{
								fontFamily: 'Montserrat-ExtraBold',
								fontSize: '26px',
								color: APP_CONFIG.mainCollors.primary,
							}}
						>
							Faça o login com o QR code
						</Typography>
						<Box className={classes.stepsContainer}>
							<Box className={classes.stepContainer}>
								<Typography
									style={{
										fontFamily: 'Montserrat-ExtraBold',
										fontSize: '19px',
										color: APP_CONFIG.mainCollors.primary,
										fontWeight: 'bold',
									}}
								>
									1
								</Typography>{' '}
								<Typography
									style={{
										fontSize: '19px',
										color: APP_CONFIG.mainCollors.primary,

										marginLeft: '10px',
									}}
								>
									Abra o app e autentique.
								</Typography>
							</Box>

							<Box className={classes.stepContainer}>
								<Typography
									style={{
										fontFamily: 'Montserrat-ExtraBold',
										fontSize: '19px',
										color: APP_CONFIG.mainCollors.primary,
										fontWeight: 'bold',
									}}
								>
									2
								</Typography>{' '}
								<Typography
									style={{
										fontSize: '19px',
										color: APP_CONFIG.mainCollors.primary,

										marginLeft: '10px',
									}}
								>
									Toque em QR code no final da tela.
								</Typography>
							</Box>
							<Box className={classes.stepContainer}>
								<Typography
									style={{
										fontFamily: 'Montserrat-ExtraBold',
										fontSize: '19px',
										color: APP_CONFIG.mainCollors.primary,
										fontWeight: 'bold',
									}}
								>
									3
								</Typography>{' '}
								<Typography
									style={{
										fontSize: '19px',
										color: APP_CONFIG.mainCollors.primary,

										marginLeft: '10px',
									}}
								>
									Aponte a câmera para o código abaixo.
								</Typography>
							</Box>
						</Box>
						<Box
							style={{
								alignSelf: 'center',
								marginTop: '50px',
							}}
						>
							<QRCode value={qrCodeValue.otp} />
						</Box>
					</Box>
				) : (
					<Box className={classes.titleContainer}>
						<Typography
							style={{
								fontSize: '26px',
								color: APP_CONFIG.mainCollors.primary,
							}}
						>
							Seja bem vindo de volta :)
						</Typography>
						<Box
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignSelf: 'center',
								alignItems: 'center',
							}}
						>
							<Grid
								style={{
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: '50px',
								}}
								container
							>
								<Grid item xs={6} sm={1}>
									<WidgetsIcon
										style={{
											color: APP_CONFIG.mainCollors.primary,
											fontSize: '40px',
										}}
									/>
								</Grid>
								<Grid item xs={6} sm={5}>
									<Typography
										style={{
											color: APP_CONFIG.mainCollors.primary,
										}}
									>
										Para acesso ao Internet Banking, nunca será
										solicitado leitura de QR Code por outro aplicativo
										que não seja o {APP_CONFIG.titleLogin}.
									</Typography>
								</Grid>
							</Grid>

							<Grid
								style={{
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: '20px',
								}}
								container
							>
								<Grid item xs={6} sm={1}>
									<DownloadIcon
										style={{
											color: APP_CONFIG.mainCollors.primary,
											fontSize: '40px',
										}}
									/>
								</Grid>
								<Grid item xs={6} sm={5}>
									<Typography
										style={{ color: APP_CONFIG.mainCollors.primary }}
									>
										Nunca será solicitado a instalação de qualquer
										outro aplicativo que não seja o{' '}
										{APP_CONFIG.titleLogin}.
									</Typography>
								</Grid>
							</Grid>
							<Grid
								style={{
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: '20px',
								}}
								container
							>
								<Grid item xs={6} sm={1}>
									<LockIcon
										style={{
											color: APP_CONFIG.mainCollors.primary,
											fontSize: '40px',
										}}
									/>
								</Grid>

								<Grid item xs={6} sm={5}>
									<Typography
										style={{ color: APP_CONFIG.mainCollors.primary }}
									>
										Nunca será solicitado envio de senha por SMS,
										e-mail, whatsapp ou qualquer outra plataforma.
									</Typography>
								</Grid>
							</Grid>
							<Grid
								style={{
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: '20px',
								}}
								container
							>
								<Grid item xs={6} sm={1}>
									<SettingsRemoteIcon
										style={{
											color: APP_CONFIG.mainCollors.primary,
											fontSize: '40px',
										}}
									/>
								</Grid>

								<Grid item xs={6} sm={5}>
									<Typography
										style={{ color: APP_CONFIG.mainCollors.primary }}
									>
										Não solicitamos nenhum tipo de instalação de
										aplicativo para acesso remoto.
									</Typography>
								</Grid>
							</Grid>
						</Box>
						<Box className={classes.titleContainer}>
							<CustomButton
								variant="contained"
								color="purple"
								onClick={handleAcessarWeb}
							>
								<Typography
									style={{
										fontSize: '10px',
										color: 'white',
									}}
								>
									ACESSAR MINHA CONTA DIGITAL PJ
								</Typography>
							</CustomButton>
							<Box style={{ marginTop: '10px' }}></Box>
							{/* <CustomButton
								variant="contained"
								color="purple"
								component={Link}
								to="/cadastro"
							>
								<Typography
									style={{
										fontSize: '10px',
										color: 'white',
									}}
								>
									CRIAR CONTA
								</Typography>
							</CustomButton>
 */}
							<Typography
								style={{
									fontSize: '15px',
									color: APP_CONFIG.mainCollors.primary,
									fontWeight: 'bold',
									marginTop: '30px',
									marginBottom: '35px',
								}}
							>
								Leia a nossa política de privacidade.
							</Typography>
						</Box>
					</Box>
				)}
			</Box>

			<Modal open={openModal}>
				<Box className={classes.modal}>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							marginTop: '30px',
						}}
					>
						<Typography
							style={{
								fontSize: '19px',
								color: APP_CONFIG.mainCollors.primary,

								marginLeft: '10px',
							}}
						>
							QrCode expirado.
						</Typography>
						<Typography
							style={{
								fontSize: '19px',
								color: APP_CONFIG.mainCollors.primary,

								marginLeft: '10px',
							}}
						>
							Volte para gerar um novo!
						</Typography>
						<Box style={{ marginTop: '30px' }}>
							<CustomButton
								color="purple"
								onClick={() => {
									setLoginSteps(false);
									setOpenModal(false);
								}}
							>
								<Typography
									style={{
										fontSize: '10px',
										color: 'white',
									}}
								>
									VOLTAR
								</Typography>
							</CustomButton>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
}
