import {
	Box,
	Divider,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	loadLinkPagamentoId,
	loadLinkPagamentoIdPagar,
} from '../../../../../actions/actions';
import { APP_CONFIG } from '../../../../../constants/config';
import useAuth from '../../../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: APP_CONFIG.mainCollors.backgrounds,
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '0 27px 27px 0',
		[theme.breakpoints.down('sm')]: {
			borderRadius: '0px',
			margin: '0px',
		},
	},
	content: {
		margin: '14px',
	},
}));

const PaymentLinkPayInfos = ({ id }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = useAuth();

	useEffect(() => {
		dispatch(loadLinkPagamentoIdPagar(id));
	}, []);

	const linkPagamentoId = useSelector((state) => state.linkPagamentoIdPagar);

	return (
		<Paper className={classes.root}>
			<Box className={classes.content}>
				<Typography
					variant="overline"
					style={{ fontSize: 14, lineHeight: 1 }}
				>
					Beneficiário - Nome/Razão Social
				</Typography>
				<Divider />
				<Typography variant="h6" style={{ fontSize: 14 }} color="initial">
					{linkPagamentoId.conta && linkPagamentoId.conta.nome
						? linkPagamentoId.conta.nome
						: null}
				</Typography>

				<Typography
					variant="h6"
					style={{ fontSize: 14, marginTop: 6 }}
					color="initial"
				>
					{linkPagamentoId.conta && linkPagamentoId.conta.razao_social
						? linkPagamentoId.conta.razao_social
						: null}
				</Typography>
			</Box>
			<Box className={classes.content}>
				<Typography
					variant="overline"
					style={{ fontSize: 14, lineHeight: 1 }}
				>
					DOCUMENTO - CPF/CNPJ
				</Typography>
				<Divider />
				<Typography variant="h6" style={{ fontSize: 14 }} color="initial">
					{linkPagamentoId.conta && linkPagamentoId.conta.documento
						? linkPagamentoId.conta.documento
						: null}
				</Typography>
				<Typography
					variant="h6"
					style={{ fontSize: 14, marginTop: 6 }}
					color="initial"
				>
					{linkPagamentoId.conta && linkPagamentoId.conta.cnpj
						? linkPagamentoId.conta.cnpj
						: null}
				</Typography>
			</Box>
			<Box className={classes.content}>
				<Typography
					variant="overline"
					style={{ fontSize: 14, lineHeight: 1 }}
				>
					Valor
				</Typography>
				<Divider />
				<Typography variant="h6" style={{ fontSize: 14 }} color="initial">
					R${' '}
					{linkPagamentoId && linkPagamentoId.valor
						? linkPagamentoId.valor
						: null}
				</Typography>
			</Box>
			<Box className={classes.content}>
				<Typography
					variant="overline"
					style={{ fontSize: 14, lineHeight: 1 }}
				>
					Limite de parcelas
				</Typography>
				<Divider />
				<Typography variant="h6" style={{ fontSize: 14 }} color="initial">
					{linkPagamentoId && linkPagamentoId.limite_parcelas
						? linkPagamentoId.limite_parcelas
						: null}
				</Typography>
			</Box>

			{linkPagamentoId && linkPagamentoId.numero_pedido ? (
				<Box className={classes.content}>
					<Typography
						variant="overline"
						style={{ fontSize: 14, lineHeight: 1 }}
					>
						Número do pedido
					</Typography>
					<Divider />
					<Typography
						variant="h6"
						style={{ fontSize: 14 }}
						color="initial"
					>
						{linkPagamentoId.numero_pedido}
					</Typography>
				</Box>
			) : null}
			{linkPagamentoId && linkPagamentoId.descricao ? (
				<Box className={classes.content}>
					<Typography
						variant="overline"
						style={{ fontSize: 14, lineHeight: 1 }}
					>
						Descrição
					</Typography>
					<Divider />
					<Typography
						variant="h6"
						style={{ fontSize: 14 }}
						color="initial"
					>
						{linkPagamentoId.descricao}
					</Typography>
				</Box>
			) : null}
		</Paper>
	);
};

export default PaymentLinkPayInfos;
