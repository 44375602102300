import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Axios from 'axios';
import { getAuthToken } from '../utils/token';
import {
	storeArquivoRemessaMutation,
	uploadArquivoRemessaMutation,
	getArquivoRemessaQuery,
	showArquivoRemessaQuery,
	getItemRemessaQuery,
	exportArquivoRetornoQuery,
	getArquivoRetornoQuery,
	getPagadoresRemessaQuery,
	getRetryItemsQuery,
} from './cnba400';

const BASE_URL = process.env.REACT_APP_API_URL;

export const api = Axios.create({
	baseURL: BASE_URL,
});

api.interceptors.request.use((config) => {
	const token = getAuthToken();

	if (token) {
		return {
			...config,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
	}

	return config;
});

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers) => {
			const token = getAuthToken();

			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			headers.set('accept', 'application/json');

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getAccounts: builder.query({
			query: (params = {}) => ({
				url: '/contas',
				params,
			}),
		}),
		uploadArquivoRemessa: builder.mutation({
			query: uploadArquivoRemessaMutation,
		}),
		storeArquivoRemessa: builder.mutation({
			query: storeArquivoRemessaMutation,
		}),
		indexArquivoRemessa: builder.query({
			query: getArquivoRemessaQuery,
		}),
		showArquivoRemessa: builder.query({
			query: showArquivoRemessaQuery,
		}),
		indexPagadoresRemessa: builder.query({
			query: getPagadoresRemessaQuery,
		}),
		indexItemRemessa: builder.query({
			query: getItemRemessaQuery,
		}),
		retryItemsRemessa: builder.query({
			query: getRetryItemsQuery,
		}),
		indexArquivoRetorno: builder.query({
			query: getArquivoRetornoQuery,
		}),
		exportArquivoRetorno: builder.mutation({
			query: exportArquivoRetornoQuery,
		}),
	}),
});

export const {
	useGetAccountsQuery,
	useStoreArquivoRemessaMutation,
	useUploadArquivoRemessaMutation,
	useIndexArquivoRemessaQuery,
	useShowArquivoRemessaQuery,
	useLazyShowArquivoRemessaQuery,
	useIndexItemRemessaQuery,
	useExportArquivoRetornoMutation,
	useIndexArquivoRetornoQuery,
	useIndexPagadoresRemessaQuery,
	useLazyRetryItemsRemessaQuery,
} = baseApi;
